(function () {
  'use strict';
  angular
    .module('garanteasyApp')
    .factory('Receipt', Receipt);

  Receipt.$inject = ['$resource', 'DateUtils', '$filter', 'API_URI'];

  function Receipt($resource, DateUtils, $filter, API_URI) {
    var resourceUrl = API_URI + 'api/receipts/:id';

    var transformResponse = function (data) {
      if (data) {
        data = angular.fromJson(data);
        data.creationDate = DateUtils.convertLocalDateFromServer(data.creationDate);
        data.buyingDate = DateUtils.convertLocalDateFromServer(data.buyingDate);
        data.paymentDate = DateUtils.convertLocalDateFromServer(data.paymentDate);
        if (data.warrantyAggregate) {
          data.warrantyAggregate.forEach(function (item) {
            if (item.warranty) {
              item.warranty.appliesFrom = DateUtils.convertLocalDateFromServer(item.warranty.appliesFrom);
              item.warranty.appliesTo = DateUtils.convertLocalDateFromServer(item.warranty.appliesTo);
            }
          })
        }

        if (!data.purchasedProducts) {
          data.purchasedProducts = [];
        }

        if (data.purchasedProductWarranty) {

          var dateFrom = data.buyingDate;

          data.purchasedProductWarranty.expirationDate = DateUtils.convertLocalDateFromServer(data.purchasedProductWarranty.expirationDate);

          if (data.purchasedProductWarranty.purchasedProduct) {
            data.purchasedProductWarranty.purchasedProduct.deliveryDate = DateUtils.convertLocalDateFromServer(data.purchasedProductWarranty.purchasedProduct.deliveryDate);
            if (data.purchasedProductWarranty.purchasedProduct.deliveryDate)
              dateFrom = data.purchasedProductWarranty.purchasedProduct.deliveryDate;
          }

          data.purchasedProductWarranty.startDate = dateFrom;


          var dateTo = data.purchasedProductWarranty.expirationDate;

          if (_.isNull(dateFrom)) {
            dateFrom = new Date();
          }
          dateFrom = new Date(dateFrom);

          const one_day = 1000 * 60 * 60 * 24;
          dateTo = new Date(dateTo);

          data.purchasedProductWarranty.dayToExpiration = Math.round((dateTo.getTime() - new Date()) / one_day);
        }
      }
      return data;
    };

    var transformResponseArray = function (data) {
      return _.map(angular.fromJson(data), transformResponse);
    };

    var transformRequest = function (dataToSend) {
      dataToSend.buyingDate = DateUtils.convertLocalDateToServer(
        dataToSend.buyingDate
      );
      dataToSend.creationDate = DateUtils.convertLocalDateToServer(
        dataToSend.creationDate
      );
      dataToSend.paymentDate = DateUtils.convertLocalDateToServer(
        dataToSend.paymentDate
      );
      if (!dataToSend.customerAddress) {
        if (dataToSend.shop) {
          if (dataToSend.shop.shopType === "ECOMMERCE") {
            if (dataToSend.customer && dataToSend.customer.addresses) {
              dataToSend.customerAddress = dataToSend.customer.addresses[0];
            }
          } else if (dataToSend.shop.address) {
            dataToSend.customerAddress = dataToSend.shop.address;
          }
        } else {
          if (dataToSend.customer && dataToSend.customer.addresses) {
            dataToSend.customerAddress = dataToSend.customer.addresses[0];
          } else {
            dataToSend.customerAddress = { country: { code: "IT" } };
          }
        }
      }
    };

    return $resource(
      resourceUrl,
      {},
      {
        query: {
          method: "GET",
          cache: true,
          isArray: true,
          transformResponse: transformResponseArray,
        },
        get: {
          method: "GET",
          cache: true,
          transformResponse: transformResponse,
        },
        getValid: {
          method: "GET",
          url: API_URI + "api/receipts/validReceipts",
          cache: true,
          transformResponse: function (data) {
            if (data) {
              data = angular.fromJson(data);
            }
            return data;
          },
        },
        update: {
          method: "PUT",
          transformRequest: function (data) {
            var dataToSend = angular.copy(data);
            // Quando si aggiornano i dati non serve rimandare le immagini
            delete dataToSend.receiptFiles;
            transformRequest(dataToSend);
            return angular.toJson(dataToSend);
          },
        },
        save: {
          method: "POST",
          transformRequest: function (data) {
            var dataToSend = angular.copy(data);
            transformRequest(dataToSend);
            return angular.toJson(dataToSend);
          },
        },
        claim: {
          method: "POST",
          url: API_URI + "api/receipts/claim",
          transformRequest: function (data) {
            var dataToSend = angular.copy(data);
            if (dataToSend.receiptIdentifier) {
              //IT100003007000012562120161205
              //01234567890123456789012345678
              //          1111111111222222222
              dataToSend.shopCode = dataToSend.receiptIdentifier.substr(4, 5);
              dataToSend.counterCode = dataToSend.receiptIdentifier.substr(
                9,
                2
              );
              var pattern = /(\d{4})(\d{2})(\d{2})/;
              dataToSend.buyingDate = new Date(
                dataToSend.receiptIdentifier
                  .substr(21, 8)
                  .replace(pattern, "$1-$2-$3")
              );
            } else {
              dataToSend.receiptIdentifier =
                "IT10" +
                dataToSend.shopCode +
                dataToSend.counterCode +
                dataToSend.receiptNumber +
                $filter("date")(dataToSend.buyingDate, "yyyyMMdd");
            }
            dataToSend.receiptNumber = dataToSend.receiptIdentifier;
            dataToSend.buyingDate = DateUtils.convertLocalDateToServer(
              dataToSend.buyingDate
            );
            return angular.toJson(dataToSend);
          },
        },
        share: {
          method: "POST",
          url: API_URI + "api/receipts/:receiptId/send/:recipient/:copyForMyself",
        },
      }
    );
  }
})();
