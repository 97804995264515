(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .factory('Password', Password);

    Password.$inject = ['$resource', 'API_URI'];

    function Password($resource, API_URI) {
        var service = $resource(API_URI + 'api/account/change_password', {}, {});

        return service;
    }
})();
