'use strict';
(function () {
  angular
    .module('garanteasyApp')
    .factory('Shop', Shop);

  Shop.$inject = ['$resource', 'API_URI'];

  function Shop($resource, API_URI) {
    var resourceUrl = API_URI + 'api/shops/:id';

    return $resource(resourceUrl, {}, {
      'query': { method: 'GET', cache: true, isArray: true },
      'get': {
        method: 'GET',
        cache: true,
        transformResponse: function (data) {
          if (data) {
            data = angular.fromJson(data);
          }
          return data;
        }
      },
      'update': { method: 'PUT' }
    });
  }
})();
