(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .factory('NotificationsSearch', NotificationsSearch);

    NotificationsSearch.$inject = ['$resource', 'API_URI'];

    function NotificationsSearch($resource, API_URI) {
        var resourceUrl = API_URI + 'api/notification-resources/_search/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true }
        });
    }
})();
