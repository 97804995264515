(function () {
  'use strict';

  var warrantyClaimComponent = {
    templateUrl: 'app/entities/warranty-claim/components/warranty-claim-dialog.component.html',
    controller: WarrantyClaimDialogController,
    controllerAs: 'vm',
    bindings: {
      warrantyClaim: '<',
      ppId: '<',
      listProducts: '<',
      purchasedProduct: '<',
      isEdit: '<',
      onSave: '<',
      onCancel: '<',
      onError: '<',
      isGeneric: '<',
      receipt: '<'
    }
  };

  angular
    .module('garanteasyApp')
    .component('warrantyClaimComponent', warrantyClaimComponent);

  WarrantyClaimDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$q', 'WarrantyClaim', 'PurchasedProduct', 'Principal', 'Customer', 'DataUtils', 'WarrantyClaimFile', '$state', 'AuthServerProvider', 'API_URI'];

  function WarrantyClaimDialogController($timeout, $scope, $stateParams, $q, WarrantyClaim, PurchasedProduct, Principal, Customer, DataUtils, WarrantyClaimFile, $state, AuthServerProvider, API_URI) {
    var vm = this;
    /* vm.ppId = $stateParams.ppId;
    vm.listProducts = $stateParams.listProducts; */
    vm.warrantyClaim = {};
    vm.datePickerOpenStatus = {};
    vm.images = [];
    vm.tmp;

    vm.hasAddress;
    vm.clear = clear;
    vm.openCalendar = openCalendar;
    vm.save = save;
    vm.checkAddressAndSave = checkAddressAndSave;
    vm.isValidInput = isValidInput;
    vm.loadPurchasedProduct = loadPurchasedProduct;

    $scope.$watch("vm.receipt", function (newValue) {
      if (vm.isGeneric && newValue) {
        vm.warrantyClaim.purchasedProduct = {};
        vm.warrantyClaim.purchasedProduct.receipt = vm.receipt;


      }
    }, true);

    vm.$onInit = function () {
      if (!vm.warrantyClaim || !vm.warrantyClaim.id) {
        vm.warrantyClaim = {
          requestDate: new Date(),
          annotations: null,
          status: 'WAITING',
          closingDate: null,
          id: null,
          notifiedShop: 0,
          notifiedGaranteasy: 0,
          purchasedProductId: $stateParams.id,
          damageType: 'DEFECT'
        };
      }
      if (!vm.warrantyClaim.damageType) {
        vm.warrantyClaim.damageType = 'DEFECT';
      }
      angular.copy(vm.warrantyClaim, vm.tmp);

      if (vm.ppId) {
        vm.warrantyClaim.purchasedProductId = vm.ppId;
        vm.loadPurchasedProduct();
      } else {
        // vm.warrantyClaim.purchasedProductId = undefined;
      }

    };

    function onSuccessPP(result) {
      vm.warrantyClaim.purchasedProduct = result;
      vm.customer = result.receipt.customer;
      if (vm.customer) {
        vm.addresses = vm.customer.addresses;
      }
      if (vm.warrantyClaim.purchasedProduct && vm.warrantyClaim.purchasedProduct.receipt && vm.warrantyClaim.purchasedProduct.receipt.customer) {
        if (vm.warrantyClaim.purchasedProduct.receipt.customer.address) {
          vm.hasAddress = true;
          vm.address = vm.warrantyClaim.purchasedProduct.receipt.customer.address;
          if (!vm.address.country) {
            vm.address.country = {};
          }
        } else {
          vm.warrantyClaim.purchasedProduct.receipt.customer.address = {};
        }
      }
      if (vm.warrantyClaim.purchasedProduct.receipt.shop && vm.warrantyClaim.purchasedProduct.receipt.shop.email) {
        vm.warrantyClaim.shopEmailAddress = vm.warrantyClaim.purchasedProduct.receipt.shop.email
      }
    }

    function onErrorPP() {

    }

    $timeout(function () {
      angular.element('.form-group:eq(1)>input').focus();
    });

    function clear() {
      if (typeof vm.onCancel === 'function') {
        angular.copy(vm.tmp, vm.customer);
        vm.onCancel();
      }
    }

    function checkAddressAndSave() {
      vm.isSaving = true;
      if (vm.warrantyClaim.purchasedProduct.product && !vm.warrantyClaim.purchasedProduct.product.transportable && !vm.hasAddress) {
        if (vm.customer.id !== null) {
          vm.customer.address = vm.address;
          Customer.update(vm.customer, save, onCustomerSaveError);
        }

      } else {
        save();
      }
    }
    function save() {
      if (vm.warrantyClaim.id !== null) {
        WarrantyClaim.update(vm.warrantyClaim, onSaveSuccess, onSaveError);
      } else {
        WarrantyClaim.save(vm.warrantyClaim, onSaveSuccess, onSaveError);
      }
    }

    function onCustomerSaveError() {
      vm.isSaving = false;
    }

    // Constraints beyond the ones in the form
    function isValidInput() {
      // For closed claims closing date is mandatory
      return vm.warrantyClaim.status == 'WAITING' || vm.warrantyClaim.status == 'OPEN' || vm.warrantyClaim.closingDate != null;
    }

    function onSaveSuccess(resultClaim) {
      if (angular.isDefined(vm.warrantyClaimFile)) {
        vm.warrantyClaimFile.warrantyClaimId = resultClaim.id;
        WarrantyClaimFile.save(vm.warrantyClaimFile, function (result) {
          vm.images.push(result);
          if (result) {
            vm.image = result;
          }
          onSaveFinal(resultClaim);
        });
      } else {
        onSaveFinal(resultClaim);
      }
    }

    function onSaveFinal(result) {
      vm.isSaving = false;
      //WarrantyClaim.send(result, onSendSuccess, onSendError);
      if (typeof vm.onSave === 'function') {
        vm.onSave(result);
      }
    }

    //function onSendSuccess(result) { }

    //function onSendError(result) { }

    function onSaveError(error) {
      vm.isSaving = false;
      if (typeof vm.onError === 'function') {
        vm.onError(error);
      }
    }

    function loadPurchasedProduct() {
      if (vm.warrantyClaim.purchasedProductId) {
        PurchasedProduct.get({
          id: vm.warrantyClaim.purchasedProductId
        }, onSuccessPP, onErrorPP);
      }
    }

    //vm.datePickerOpenStatus.requestDate = false;
    vm.datePickerOpenStatus.closingDate = false;

    function openCalendar(date) {
      vm.datePickerOpenStatus[date] = true;
    }

    vm.canShow = function (contentType) {
      return contentType != null && contentType.toLowerCase().indexOf('image') == 0 && contentType.toLowerCase().indexOf('tiff') == -1;
    }

    vm.uploadFile = function ($file) {
      vm.warrantyClaimFile = {};
      vm.byteSize = DataUtils.byteSize;
      vm.openFile = DataUtils.openFile;
      vm.fileTooLarge = false;
      if ($file && $file.$error === 'pattern') {
        return;
      }
      if ($file) {
        if ($file.size > 5120000) {
          vm.fileTooLarge = true;
          // Reset data in case there was a previous image selected
          vm.warrantyClaimFile.fileName = null;
          vm.warrantyClaimFile.fileData = null;
          vm.warrantyClaimFile.contentType = null;
          vm.warrantyClaimFile.type = null;
        } else {
          vm.fileTooLarge = false;
          DataUtils.toBase64($file, function (base64Data) {
            $scope.$apply(function () {
              vm.warrantyClaimFile.fileData = base64Data;
              vm.warrantyClaimFile.fileContentType = $file.type;
              vm.warrantyClaimFile.fileName = $file.name;
              vm.image = vm.warrantyClaimFile;
              /*if(item.fileContentType && item.fileContentType.toLowerCase().indexOf("image") == 0){*/
              vm.imageNotPersistent = vm.warrantyClaimFile;

            });
          });
        }
      }
    };

    var imagesPath = {};
    vm.getImageUrl = function (warrantyClaimFile) {
      if (!warrantyClaimFile || !warrantyClaimFile.id) {
        imagesPath[warrantyClaimFile.id] = null;
      } else if (_.isUndefined(imagesPath[warrantyClaimFile.id])) {
        imagesPath[warrantyClaimFile.id] = API_URI + "api/warranty-claim-files/" + warrantyClaimFile.id + "?width=-&height=377&token=" + AuthServerProvider.getToken();
      }
      return imagesPath[warrantyClaimFile.id];

    };

  }
})();
