(function () {
    'use strict';
    angular
        .module('garanteasyApp')
        .factory('CardType', CardType);

    CardType.$inject = ['$resource', 'API_URI'];

    function CardType($resource, API_URI) {
        var resourceUrl = API_URI + 'api/card-types/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true },
            /*'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },*/
            'update': { method: 'PUT' },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var dataToSend = angular.copy(data);
                    return angular.toJson(dataToSend);
                }
            }
        });
    }
})();
