'use strict';
(function () {

    angular
        .module('garanteasyApp')
        .factory('CmsShopService', CmsShopService);

    CmsShopService.$inject = ['$http', 'CMS_URI', '$log'];

    function CmsShopService($http, CMS_URI, $log) {
        var service = {
            getShopData: getShopData,
            getCompanyData: getCompanyData
        };

        return service;



        function getShopData(id) {
            if (id) {
                var uri = CMS_URI + 'api/view/shop/' + id;
                return call(uri);
            }
        }

        function getCompanyData(id) {
            if (id) {
                var uri = CMS_URI + 'api/view/company/' + id;
                return call(uri);
            }
        }

        function call(uri) {
            return $http.get(uri, { noAuth: true })
                .then(getData)
                .catch(error);

            function getData(response) {
                return response.data;
            }

            function error(error) {
                $log.error('XHR Failed for brands.' + error.data);
            }
        }

    }
})();
