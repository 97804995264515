'use strict';
(function () {

    angular
        .module('garanteasyApp')
        .factory('CmsWarrantyService', CmsWarrantyService);

    CmsWarrantyService.$inject = ['$http', 'CMS_URI', '$log'];

    function CmsWarrantyService($http, CMS_URI, $log) {
        var service = {
            getWarrantyData: getWarrantyData,
            getExtensionData: getExtensionData
        };

        return service;



        function getWarrantyData(id) {
            if (id) {
                var uri = CMS_URI + 'api/view/warranty/' + id;
                return call(uri);
            }
        }

        function getExtensionData(id) {
            if (id) {
                var uri = CMS_URI + 'api/view/extension-coverage/' + id;
                return call(uri);
            }
        }

        function call(uri) {
            return $http.get(uri, { noAuth: true })
                .then(getData)
                .catch(error);

            function getData(response) {
                return response.data;
            }

            function error(error) {
                $log.error('XHR Failed for brands.' + error.data);
            }
        }

    }
})();
