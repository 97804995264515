(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('warrantyClaimRule', {
                parent: 'entity',
                url: '/warrantyClaimRule?page&sort&search',
                ncyBreadcrumb: {
                    label: "{{'garanteasyApp.warrantyClaimRule.home.title' | translate}}"
                },
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'garanteasyApp.warrantyClaimRule.home.title'
                },
                views: {
                    'sidebar@': {
                        templateUrl: 'app/layouts/sidebar/sidebar.html',
                        controller: 'SidebarController',
                        controllerAs: 'vm'
                    },
                    'content@': {
                        templateUrl: 'app/entities/warrantyClaimRule/warrantyClaimRules.html',
                        controller: 'WarrantyClaimRuleController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '0',
                        squash: true
                    },
                    sort: {
                        value: 'name,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('brand');
                        $translatePartialLoader.addPart('warrantyClaimRule');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('warrantyClaimRule-detail', {
                parent: 'entity',
                url: '/warrantyClaimRule/{id}?from&from2nd',
                ncyBreadcrumb: {
                    label: "{{'garanteasyApp.warrantyClaimRule.detail.title' | translate}}",
                    parent: function ($scope) {
                        return $scope.from || $scope.from2nd || 'warrantyClaimRule';
                    }
                },
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'garanteasyApp.warrantyClaimRule.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/warrantyClaimRule/warrantyClaimRule-detail.html',
                        controller: 'WarrantyClaimRuleDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('warrantyClaimRule');
                        $translatePartialLoader.addPart('brand');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'WarrantyClaimRule', function ($stateParams, WarrantyClaimRule) {
                        return WarrantyClaimRule.get({ id: $stateParams.id }).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'warrantyClaimRule',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('warrantyClaimRule-detail.edit', {
                parent: 'warrantyClaimRule-detail',
                url: '/detail/edit',
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/warrantyClaimRule/warrantyClaimRule-dialog.html',
                        controller: 'WarrantyClaimRuleDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['WarrantyClaimRule', function (WarrantyClaimRule) {
                                return WarrantyClaimRule.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, { reload: false });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('warrantyClaimRule.new', {
                parent: 'warrantyClaimRule',
                url: '/new',
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/warrantyClaimRule/warrantyClaimRule-dialog.html',
                        controller: 'WarrantyClaimRuleDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    id: null,
                                    merchant: null,
                                    shop: null,
                                    category: null,
                                    product: null,
                                    brand: null,
                                    informativa: '',
                                    freshdeskKey: ''
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('warrantyClaimRule', null, { reload: true });
                    }, function () {
                        $state.go('warrantyClaimRule');
                    });
                }]
            })
            .state('warrantyClaimRule.edit', {
                parent: 'warrantyClaimRule',
                url: '/{id}/edit',
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/warrantyClaimRule/warrantyClaimRule-dialog.html',
                        controller: 'WarrantyClaimRuleDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['WarrantyClaimRule', function (WarrantyClaimRule) {
                                return WarrantyClaimRule.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('warrantyClaimRule', null, { reload: true });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('warrantyClaimRule.delete', {
                parent: 'warrantyClaimRule',
                url: '/{id}/delete',
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/warrantyClaimRule/warrantyClaimRule-delete-dialog.html',
                        controller: 'WarrantyClaimRuleDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['WarrantyClaimRule', function (WarrantyClaimRule) {
                                return WarrantyClaimRule.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('warrantyClaimRule', null, { reload: true });
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
