(function () {
    'use strict';
    angular
        .module('garanteasyApp')
        .factory('UserData', UserData);

    UserData.$inject = ['$resource', 'API_URI'];

    function UserData($resource, API_URI) {
        var resourceUrl = API_URI + 'api/account/send_data';

        return $resource(resourceUrl, {}, {
            'send': { method: 'GET', isArray: false },


        });
    }
})();
