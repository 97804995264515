(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .controller('ExtensionLandingController', ExtensionLandingController);

  ExtensionLandingController.$inject = ['$state', '$stateParams', '$scope', '$rootScope', '$q', 'Receipt', 'Principal', 'PurchasedExtensionWarranty', '$log'];

  function ExtensionLandingController($state, $stateParams, $scope, $rootScope, $q, Receipt, Principal, PurchasedExtensionWarranty, $log) {
    var vm = this;
    vm.checkAccess = checkAccess;
    vm.receipt = {};
    vm.init = init;
    //vm.extension = { id: "1010" };


    vm.init();

    checkAccess();

    function init() {
      if ($stateParams.receiptId) {
        Receipt.get({ id: $stateParams.receiptId }, onSuccessLoadReceipt, onErrorLoadReceipt);
      }
      if ($stateParams.extensionId) {
        $log.debug("Fetching details for warranty product", $stateParams.extensionId)
        PurchasedExtensionWarranty.get({ id: $stateParams.extensionId }, onSuccessLoadExtension, onErrorLoadExtension);
      }
    }

    function onSuccessLoadExtension(result) {
      vm.extension = result;
      vm.selectedProductId = result.linkedProductId
    }
    function onErrorLoadExtension(result) {
      $log.error("Impossibile caricare l'estensione", $stateParams.extensionId, result);
    }



    function onSuccessLoadReceipt(result) {
      $log.debug("Ricevuta", result);
      vm.receipt = result;
    }



    function onErrorLoadReceipt() {
      $log.error("Impossibile caricare la ricevuta", error);
    }

    function onSaveSuccess(result) {
      vm.isSaving = false;
    }

    function onSaveError() {
      vm.isSaving = false;
    }

    function checkAccess() {
      Principal.identity().then(function (account) {
        vm.currentUserId = account.id;
        if (vm.receipt.id && vm.receipt.customer) {
          vm.isOwner = vm.receipt.customer.user.id === account.id;
        } else {
          // New receipt
          vm.isOwner = true;
        }
        Principal.hasAuthority('ROLE_ADMIN').then(function (result) {
          if (result) {
            vm.isAdmin = true;
          } else {
            vm.isAdmin = false;
          }
        });
      });
    }

  }
})();
