(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .factory('ElasticsearchReindex', ElasticsearchReindex);

    ElasticsearchReindex.$inject = ['$resource', 'API_URI'];

    function ElasticsearchReindex($resource, API_URI) {
        var service = $resource(API_URI + 'api/elasticsearch/index', {}, {
            'reindex': { method: 'POST' }
        });

        return service;
    }
})();
