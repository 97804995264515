(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .factory('Register', Register);

    Register.$inject = ['$resource', 'API_URI'];

    function Register($resource, API_URI) {
        return $resource(API_URI + 'api/register', {}, {});
    }
})();
