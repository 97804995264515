(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .factory('PurchasedProductWarrantySearch', PurchasedProductWarrantySearch);

  PurchasedProductWarrantySearch.$inject = ['$resource', 'DateUtils', '$log', 'API_URI'];

  function PurchasedProductWarrantySearch($resource, DateUtils, $log, API_URI) {
    var resourceUrl = API_URI + 'api/_search/purchased-product-warranties/:id';

    var transformResponseArray = function (data, headersGetter, status) {
      if (!Array.isArray(data)) {
        data = JSON.parse(data);
      }
      if (status === 200) {
        return data;
        /* return _.map(angular.fromJson(data), transformResponse); */
      } else {
        $log.error("Errore fetching data", data);
        return null;
      }
    };
    return $resource(resourceUrl, {}, {
      'query': { method: 'GET', isArray: true, transformResponse: transformResponseArray }
    });
  }
})();
