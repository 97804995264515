(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .controller('PurchasedProductWarrantyDetailController', PurchasedProductWarrantyDetailController);

  PurchasedProductWarrantyDetailController.$inject = ['$state', '$scope', '$rootScope', '$q', 'ReceiptFile', 'DataUtils', 'entity', 'Principal', 'Receipt', 'Customer', 'Contact', 'PurchasedProduct', 'AlertService', '$translate', 'Shop', 'ShopLookup', 'Card', 'Address', 'PhoneNumber', 'ProductLookup', 'BrandLookup', 'Warranty', 'PurchasedProductWarranty', 'BrandService', 'CmsWarrantyService', 'CMS_URI', '$log'];

  function PurchasedProductWarrantyDetailController($state, $scope, $rootScope, $q, ReceiptFile, DataUtils, entity, Principal, Receipt, Customer, Contact, PurchasedProduct, AlertService, $translate, Shop, ShopLookup, Card, Address, PhoneNumber, ProductLookup, BrandLookup, Warranty, PurchasedProductWarranty, BrandService, CmsWarrantyService, CMS_URI, $log) {
    var vm = this;

    vm.name = 'purchased.product.warranty.detail.controller';
    vm.purchasedProductWarranty = entity;
    setModel(entity.warranty);
    vm.receipt = vm.purchasedProductWarranty.receipt;
    vm.purchasedProduct = vm.purchasedProductWarranty.purchasedProduct;
    $scope.stateData.vm = vm;
    vm.isOwner = null;
    vm.isAdmin = null;
    vm.edit = false;
    vm.currentUserId;
    vm.onUpdateWarranty = onUpdateWarranty;
    vm.editWarranty = editWarranty;
    vm.cancelEdit = cancelEdit;
    vm.saveUpdatePurchasedProductWarranty = saveUpdatePurchasedProductWarranty;
    vm.onSave = onSave;
    vm.oldWarranty = null;
    vm.isOfficial = vm.purchasedProductWarranty.warranty.official;
    vm.resolveGuarantor = resolveGuarantor;
    vm.cmsWarrantyData;
    vm.resolveCmsData = resolveCmsData;
    vm.productBrandName = "";
    vm.productBrandLogo = "";
    vm.cmsUri = CMS_URI;
    vm.saveSecond = function () {
      Receipt.update(vm.receipt, onSaveSuccess, onSaveError);
    };
    vm.openCalendar = openCalendar;
    vm.datePickerOpenStatus = {};
    vm.datePickerOpenStatus.paymentDate = false;
    vm.convertIcon = convertIcon;
    vm.addExtraWarrantyToProduct = addExtraWarrantyToProduct;
    vm.onUpdateExtraWarranty = onUpdateExtraWarranty;

    vm.checkAccess = checkAccess;
    checkAccess();
    fixDays();
    loadProduct();
    loadWarranty();
    resolveCmsData();
    function setModel(result) {
      switch (result.contractType) {
        case "CONVENTIONAL":
          vm.warrentyModel = {
            'image': 'azienda.png',
            'icon': 'garanteasy-fabbricabucato',
            'title': 'garanteasyApp.receipt.detail.infoConventionalWarrantyMobile'
          };
          break;
        case "LEGAL":
          vm.warrentyModel = {
            'image': 'bilancia_scudo_bianco.png',
            'icon': 'garanteasy-bilanciabucata',
            'title': 'garanteasyApp.receipt.detail.infoLegalWarrantyMobile'
          };
          break;
        case "INSURANCE_POLICY":
          vm.warrentyModel = {
            'image': 'ombrello_scudo_bianco.png',
            'icon': 'garanteasy-ombrellobucato',
            'title': 'garanteasyApp.receipt.detail.infoInsuranceWarrantyMobile'
          };
          break;
        case "SERVICE_CONTRACT":
          vm.warrentyModel = {
            'image': 'assistenza_1scudo_bianco.png',
            'icon': 'garanteasy-assistenzabucato',
            'title': 'garanteasyApp.receipt.detail.infoServiceContractWarranryMobile'
          };
          break;
        default:
          vm.warrentyModel = {
            'image': 'azienda.png',
            'icon': 'garanteasy-fabbricabucato',
            'title': 'garanteasyApp.receipt.detail.infoLegalWarrantyMobile'
          };
      }
    }
    function loadWarranty() {
      if (!vm.isOfficial) {
        Warranty.get({
          id: vm.purchasedProductWarranty.warranty.id
        }, function (result) {
          vm.purchasedProductWarranty.warranty = result;
          setModel(result);
        });
      }
    }

    function loadProduct() {
      if (vm.purchasedProductWarranty && vm.purchasedProductWarranty.purchasedProduct) {
        PurchasedProduct.get({ id: vm.purchasedProductWarranty.purchasedProduct.id }, function (p) {
          vm.purchasedProduct.purchasedProductWarranties = p.purchasedProductWarranties;
        });
      }
    }

    function openCalendar(date) {
      vm.datePickerOpenStatus[date] = true;
    }

    function convertIcon(warrantyExtension) {
      if (warrantyExtension) {
        if (warrantyExtension.warranty.guarantorType === 'PRODUCER') {
          return "../../../content/images/icone_new/icone_sfondo_bianco/azienda.png";
        }
        if (warrantyExtension.warranty.guarantorType === 'INSURANCE') {
          return "../../../content/images/icone_new/icone_sfondo_bianco/ombrello_scudo_bianco.png";
        }
        if (warrantyExtension.warranty.guarantorType === 'ASSISTANCE_CENTER') {
          return "../../../content/images/icone_new/icone_sfondo_bianco/assistenza_1scudo_bianco.png";
        }
      }
      return "../../../content/images/icone_new/icone_sfondo_bianco/punto_domanda.png";
    }

    function addExtraWarrantyToProduct() {
      if (!vm.purchasedProduct.purchasedProductWarranties)
        vm.purchasedProduct.purchasedProductWarranties = [];
      vm.purchasedProduct.purchasedProductWarranties.push(vm.purchasedProduct.warrantyExtension);
      vm.purchasedProduct.warrantyExtension = {};
    }

    function onUpdateExtraWarranty(purchasedProductWarranty) {
      vm.purchasedProduct.warrantyExtension = Object.assign({}, purchasedProductWarranty);
    }


    function editWarranty() {
      if (!vm.isOfficial) {
        vm.oldWarranty = angular.copy(vm.purchasedProductWarranty);
        vm.edit = true;
      }
    }

    function fixDays() {
      if (vm.purchasedProductWarranty.status === 'valid' || vm.purchasedProductWarranty.status === 'expiring' && vm.purchasedProductWarranty.expirationDate) {
        const one_day = 1000 * 60 * 60 * 24;
        var dateTo = new Date(vm.purchasedProductWarranty.expirationDate);
        vm.purchasedProductWarranty.dayToExpiration = Math.round((dateTo.getTime() - new Date()) / one_day);
      }
    }

    function resolveGuarantor(purchasedProductWarranty) {
      if (purchasedProductWarranty.warranty.contractType === 'LEGAL') {
        if (purchasedProductWarranty.receipt && purchasedProductWarranty.receipt.shop && purchasedProductWarranty.receipt.shop.description) {
          return purchasedProductWarranty.receipt.shop.description;
        }
      }
      if (purchasedProductWarranty.warranty.contractType === 'CONVENTIONAL') {
        if (purchasedProductWarranty.purchasedProduct && purchasedProductWarranty.purchasedProduct.product && purchasedProductWarranty.purchasedProduct.product.producer && purchasedProductWarranty.purchasedProduct.product.producer.name) {
          if (vm.cmsWarrantyData && vm.cmsWarrantyData.holder_company) {
            return vm.cmsWarrantyData.holder_company;
          }
          return purchasedProductWarranty.purchasedProduct.product.producer.name;
        }
      }
      if (purchasedProductWarranty.warranty.contractType === 'SERVICE_CONTRACT' ||
        purchasedProductWarranty.warranty.contractType === 'INSURANCE_POLICY'
      ) {
        return purchasedProductWarranty.warranty.description;
      }
    }

    function cancelEdit() {
      vm.purchasedProductWarranty = vm.oldWarranty;
      vm.oldWarranty = null;
      vm.edit = false;
    }

    function onUpdateWarranty(purchasedProductWarranty, valid) {
      if (!purchasedProductWarranty) {
        return;
      }
    }

    function resolveCmsData() {
      //resolve brand
      if (vm.purchasedProductWarranty && vm.purchasedProductWarranty.purchasedProduct && vm.purchasedProductWarranty.purchasedProduct.product && vm.purchasedProductWarranty.purchasedProduct.product.brand && vm.purchasedProductWarranty.purchasedProduct.product.brand.cmsReference) {
        BrandService.getBrandData(vm.purchasedProductWarranty.purchasedProduct.product.brand.cmsReference).then(function (response) {
          vm.productBrandName = response[0].title;
          vm.productBrandLogo = CMS_URI + response[0].brand_image_full;
        });
      } else {
        vm.productBrandName = vm.purchasedProduct ? (vm.purchasedProductWarranty.purchasedProduct.product.brand ? vm.purchasedProductWarranty.purchasedProduct.product.brand.name : vm.purchasedProductWarranty.purchasedProduct.product.brandAndName) : "";
      }
      //resolve warranty
      //vm.purchasedProductWarranty.warranty.reference ="10ae5337-30e4-4144-b632-c28b62d02413";
      if (vm.purchasedProductWarranty && vm.purchasedProductWarranty.warranty && vm.purchasedProductWarranty.warranty.cmsReference) {
        //load data from cms
        CmsWarrantyService.getWarrantyData(vm.purchasedProductWarranty.warranty.cmsReference).then(function (response) {
          vm.cmsWarrantyData = response[0];
        });
      }
    }

    function onSave() {
      vm.saveUpdatePurchasedProductWarranty(vm.purchasedProductWarranty, vm.oldWarranty).then(function (res) {
        onSaveSuccess(res);
      }).catch(function (error) {
        onSaveError();
      });
    }

    function onSaveSuccess(purchasedProductWarranty) {
      vm.edit = false;
    }

    function onSaveError() {

    }

    function checkAccess() {
      Principal.identity().then(function (account) {
        vm.currentUserId = account.id;
        if (vm.receipt.id && vm.receipt.customer) {
          vm.isOwner = vm.receipt.customer.user.id === account.id;
        } else {
          // New receipt
          vm.isOwner = true;
        }
        Principal.hasAuthority('ROLE_ADMIN').then(function (result) {
          if (result) {
            vm.isAdmin = true;
          } else {
            vm.isAdmin = false;
          }
        });
      });
    }

    function saveUpdatePurchasedProductWarranty(purchasedProductWarranty, old) {
      delete purchasedProductWarranty.receipt;
      var promise = new Promise(function (resolve) {
        resolve(purchasedProductWarranty);
      });
      if (!purchasedProductWarranty.warranty.id) {
        promise = Warranty.save(purchasedProductWarranty.warranty).$promise;
      } else if (!purchasedProductWarranty.warranty.official && purchasedProductWarranty.warranty.id && purchasedProductWarranty.warranty.code === 'CUSTOM') {
        promise = Warranty.update(purchasedProductWarranty.warranty).$promise.then(function (result) {
          purchasedProductWarranty.warranty = result;
          return purchasedProductWarranty;
        });
      }
      if (!purchasedProductWarranty.id) {
        promise = promise.then(function (warranty) {
          purchasedProductWarranty.warrantyId = purchasedProductWarranty.warrantyId || purchasedProductWarranty.warranty.id || warranty.id;
          return PurchasedProductWarranty.save(purchasedProductWarranty).$promise;
        });
      } else if (purchasedProductWarranty.id && old && purchasedProductWarranty.warranty.id !== old.warranty.id) {
        promise = promise.then(function (warranty) {
          return PurchasedProductWarranty.delete({
            id: old.id
          }).$promise;
        }).then(function (warranty) {
          purchasedProductWarranty.id = null;
          purchasedProductWarranty.warrantyId = purchasedProductWarranty.warrantyId || purchasedProductWarranty.warranty.id || warranty.id;
          return PurchasedProductWarranty.save(purchasedProductWarranty).$promise;
        });
      }
      return promise.then(function (result) {
        purchasedProductWarranty = result;
        return purchasedProductWarranty;
      });
    }

  }
})();
