(function () {
  'use strict';
  angular
    .module('garanteasyApp')
    .factory('PrivacyPolicy', PrivacyPolicy);

  PrivacyPolicy.$inject = ['$resource', 'API_URI'];

  function PrivacyPolicy($resource, API_URI) {
    var resourceUrl = API_URI + 'api/privacy-policies/:id';

    return $resource(resourceUrl, {}, {

      'reg': {
        method: 'GET',
        isArray: true,
        url: API_URI + 'api/privacy-policies/reg',
        transformResponse: function (data) {
          if (data) {
            data = angular.fromJson(data);
          }
          return data;
        }
      },
      'regMerchantCode': {
        method: 'GET',
        isArray: true,
        url: API_URI + 'api/privacy-policies/reg/:merchant_code',
        transformResponse: function (data) {
          if (data) {
            data = angular.fromJson(data);
          }
          return data;
        }
      },
      'requiredGet': {
        method: 'GET',
        isArray: true,
        url: API_URI + 'api/privacy-policies/required',
        transformResponse: function (data) {
          if (data) {
            data = angular.fromJson(data);
          }
          return data;
        }
      },
      'requiredPost': {
        method: 'POST',
        url: API_URI + 'api/privacy-policies/required',
        isArray: true
        /* transformRequest: function (data) {
             var dataToSend = angular.copy(data);
             return angular.toJson(dataToSend);
         }*/
      }
    });
  }
})();
