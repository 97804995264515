(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .factory('User', User);

    User.$inject = ['$resource', 'API_URI'];

    function User($resource, API_URI) {
        var service = $resource(API_URI + 'api/users/:login', {}, {
            'query': { method: 'GET', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': { method: 'POST' },
            'update': { method: 'PUT' },
            'delete': { method: 'DELETE' }
        });

        return service;
    }
})();
