'use strict';
(function () {

  angular
    .module('garanteasyApp')
    .factory('errorHandlerInterceptor', errorHandlerInterceptor);

  errorHandlerInterceptor.$inject = ['$q', '$rootScope', '$log'];

  function errorHandlerInterceptor($q, $rootScope, $log) {
    var service = {
      responseError: responseError
    };

    return service;

    function responseError(response) {
      $log.debug("Error handler interceptor", response);
      if (response.config && response.config.method === 'DELETE') {
        response.data.message = "Impossibile eliminare il dato richiesto, controlla che non sia utilizzato in un documento di acquisto.";
      }
      if (!(response.status === 401 && (response.data === '' || (response.data.path && response.data.path.indexOf('/api/account') === 0)))) {
        $rootScope.$emit('garanteasyApp.httpError', response);
      }
      return $q.reject(response);
    }
  }
})();
