'use strict';
(function () {

  angular
    .module('garanteasyApp')
    .factory('ExtensionService', ExtensionService);

  ExtensionService.$inject = ['$http', '$log', 'API_URI'];

  function ExtensionService($http, $log, API_URI) {
    const uri = API_URI + 'api/purchased-product-warranties/linkWarrantyToPurchasedProduct';

    var service = {
      linkWarrantyToPurchasedProduct: linkWarrantyToPurchasedProduct,
      updateLinkWarrantyToPurchasedProduct: updateLinkWarrantyToPurchasedProduct
    };

    return service;

    function linkWarrantyToPurchasedProduct(warrantyPurchasedProductId, purchasedProductId) {
      var request = {
        warrantyPurchasedProductId: parseInt(warrantyPurchasedProductId),
        purchasedProductId: parseInt(purchasedProductId)
      };
      return $http.put(uri, request);
    }

    function updateLinkWarrantyToPurchasedProduct(warrantyPurchasedProductId, newPurchasedProductId, linkedProductId) {
      var urlWithParams = uri + "/" + [parseInt(warrantyPurchasedProductId),
      parseInt(newPurchasedProductId),
      parseInt(linkedProductId)].join('/');
      return $http.put(urlWithParams);
    }

  }
})();
