(function () {
    'use strict';
    angular
        .module('garanteasyApp')
        .factory('Address', Address);

    Address.$inject = ['$resource', 'API_URI'];

    function Address($resource, API_URI) {
        var resourceUrl = API_URI + 'api/addresses/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method: 'PUT' }
        });
    }
})();
