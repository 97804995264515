(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
      .state('warranty', {
        parent: 'entity',
        url: '/warranty?page&sort&search',
        data: {
          authorities: ['ROLE_USER'],
          pageTitle: 'garanteasyApp.warranty.home.title'
        },
        views: {
          'content@': {
            templateUrl: 'app/entities/warranty/warranties.html',
            controller: 'WarrantyController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '0',
            squash: true
          },
          sort: {
            value: 'id,asc',
            squash: true
          },
          search: null
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
            return {
              page: PaginationUtil.parsePage($stateParams.page),
              sort: $stateParams.sort,
              predicate: PaginationUtil.parsePredicate($stateParams.sort),
              ascending: PaginationUtil.parseAscending($stateParams.sort),
              search: $stateParams.search
            };
          }],
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('warranty');
            $translatePartialLoader.addPart('timeUnit');
            $translatePartialLoader.addPart('global');
            return $translate.refresh();
          }]
        }
      })
      .state('warranty-detail', {
        parent: 'entity',
        url: '/warranty/{id}',
        data: {
          authorities: ['ROLE_USER'],
          pageTitle: 'garanteasyApp.warranty.detail.title'
        },
        views: {
          'content@': {
            templateUrl: 'app/entities/warranty/warranty-detail.html',
            controller: 'WarrantyDetailController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('warranty');
            $translatePartialLoader.addPart('timeUnit');
            $translatePartialLoader.addPart('receipt');
            return $translate.refresh();
          }],
          entity: ['$stateParams', 'Warranty', function ($stateParams, Warranty) {
            return Warranty.get({ id: $stateParams.id }).$promise;
          }],
          previousState: ["$state", function ($state) {
            var currentStateData = {
              name: $state.current.name || 'warranty',
              params: $state.params,
              url: $state.href($state.current.name, $state.params)
            };
            return currentStateData;
          }]
        }
      })
      .state('warranty-detail.edit', {
        parent: 'warranty-detail',
        url: '/detail/edit',
        data: {
          authorities: ['ROLE_USER']
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
          $uibModal.open({
            templateUrl: 'app/entities/warranty/warranty-dialog.html',
            controller: 'WarrantyDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
              entity: ['Warranty', function (Warranty) {
                return Warranty.get({ id: $stateParams.id }).$promise;
              }]
            }
          }).result.then(function () {
            $state.go('^', {}, { reload: false });
          }, function () {
            $state.go('^');
          });
        }]
      })
      .state('warranty.new', {
        parent: 'warranty',
        url: '/new',
        data: {
          authorities: ['ROLE_USER']
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
          $uibModal.open({
            templateUrl: 'app/entities/warranty/warranty-dialog.html',
            controller: 'WarrantyDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
              entity: function () {
                return {
                  code: null,
                  description: null,
                  reference: null,
                  appliesFrom: null,
                  appliesTo: null,
                  legal: false,
                  validity: null,
                  validityUnit: null,
                  official: 0,
                  id: null
                };
              }
            }
          }).result.then(function () {
            $state.go('warranty', null, { reload: true });
          }, function () {
            $state.go('warranty');
          });
        }]
      })
      .state('warranty.edit', {
        parent: 'warranty',
        url: '/{id}/edit',
        data: {
          authorities: ['ROLE_USER']
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
          $uibModal.open({
            templateUrl: 'app/entities/warranty/warranty-dialog.html',
            controller: 'WarrantyDialogController',
            controllerAs: 'vm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
              entity: ['Warranty', function (Warranty) {
                return Warranty.get({ id: $stateParams.id }).$promise;
              }]
            }
          }).result.then(function () {
            $state.go('warranty', null, { reload: true });
          }, function () {
            $state.go('^');
          });
        }]
      })
      .state('warranty.delete', {
        parent: 'warranty',
        url: '/{id}/delete',
        data: {
          authorities: ['ROLE_USER']
        },
        onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
          $uibModal.open({
            templateUrl: 'app/entities/warranty/warranty-delete-dialog.html',
            controller: 'WarrantyDeleteController',
            controllerAs: 'vm',
            size: 'md',
            resolve: {
              entity: ['Warranty', function (Warranty) {
                return Warranty.get({ id: $stateParams.id }).$promise;
              }]
            }
          }).result.then(function () {
            $state.go('warranty', null, { reload: true });
          }, function () {
            $state.go('^');
          });
        }]
      });
  }

})();
