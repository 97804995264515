(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .factory('AddressSearch', AddressSearch);

    AddressSearch.$inject = ['$resource', 'API_URI'];

    function AddressSearch($resource, API_URI) {
        var resourceUrl = API_URI + 'api/_search/addresses/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true }
        });
    }
})();
