(function () {
    'use strict';
    angular
        .module('garanteasyApp')
        .factory('Maintenance', Maintenance);

    Maintenance.$inject = ['$resource', 'DateUtils', '$stateParams', 'API_URI'];

    function Maintenance($resource, DateUtils, $stateParams, API_URI) {
        var resourceUrl = API_URI + 'api/product-maintenance/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var dataToSend = angular.copy(data);
                    if (dataToSend.startType)
                        dataToSend.startType = dataToSend.startType.toUpperCase();
                    dataToSend.intervalType = dataToSend.intervalType.toUpperCase();
                    return angular.toJson(dataToSend);
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var dataToSend = angular.copy(data);
                    if (dataToSend.startType)
                        dataToSend.startType = dataToSend.startType.toUpperCase();
                    dataToSend.intervalType = dataToSend.intervalType.toUpperCase();
                    return angular.toJson(dataToSend);
                }
            },

        });

    }
})();
