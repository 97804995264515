(function () {
    'use strict';
    angular
        .module('garanteasyApp')
        .factory('CardCustomer', CardCustomer);

    CardCustomer.$inject = ['$resource', 'DateUtils', 'API_URI'];

    function CardCustomer($resource, DateUtils, API_URI) {
        var resourceUrl = API_URI + 'api/card-customers/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var dataToSend = angular.copy(data);
                    return angular.toJson(dataToSend);
                }
            },
            'connectTOUser': {
                method: 'PUT',
                url: API_URI + 'api/card-customers/connectToUser',
                transformRequest: function (data) {
                    var dataToSend = angular.copy(data);
                    return angular.toJson(dataToSend);
                }
            }
        });
    }
})();
