'use strict';
(function () {

  angular
    .module('garanteasyApp')
    .factory('CmsService', CmsService);

  CmsService.$inject = ['$http', 'CMS_URI', '$log'];

  function CmsService($http, CMS_URI, $log) {
    var service = {
      getFaq: getFaq,
      getNodeData: getNodeData,
      getData: getData,
      getFaqData: getFaqData,
      searchBrandFromCode: searchBrandFromCode,
      getBrandImageData: getBrandImageData,
      getExtensionBrandByCode: getExtensionBrandByCode,
      getExtensionDraftBrandByCode: getExtensionDraftBrandByCode,
      getBrandByCode: getBrandByCode,
      call: call
    };

    return service;

    function getNodes() {

    }
    function getFaq(lang) {
      var uri = CMS_URI + (lang || 'it') + '/api/view/faq/customer';
      return $http.get(uri);
    }
    function getNodeData(lang, node, fields) {
      if (lang && node) {
        var uri = CMS_URI + lang + '/api/node/' + node + '?filter[destinatari.name]=Consumer';
        if (fields && fields.length) {
          uri += '&fields[node--' + node + ']=' + fields.join(',');
        }
        return call(uri);
      }
    }

    function getData(lang, node, id, fields) {
      if (lang && node && id) {
        var uri = CMS_URI + lang + '/api/node/' + node + '/' + id + '?filter[destinatari.name]=Consumer';
        if (fields && fields.length) {
          uri += '&fields[node--' + node + ']=' + fields.join(',');
        }
        return call(uri);
      }
    }

    function getFaqData(target, category, reference, lang) {
      target = checkNull(target);
      category = checkNull(category);
      reference = checkNull(reference);
      lang = checkNull(lang);

      var uri = CMS_URI + lang + '/api/view/faq/' + target + '/' + category + '/' + reference + '/' + lang;
      return call(uri);
    }

    function checkNull(value) {
      if (!value) {
        value = 'all';
      }
      return value;
    }



    function searchBrandFromCode(lang, code) {
      if (lang && code) {
        var uri = CMS_URI + lang + '/api/node/censimento_brand/?filter[code]=' + code;
        return call(uri);
      }
    }

    function getBrandImageData(lang, cmsBrandReference) {
      if (cmsBrandReference) {
        var uri = CMS_URI + lang + '/api/node/censimento_brand/' + cmsBrandReference + '/immagine_del_brand_completo';
        return call(uri);
      }
    }

    function getExtensionBrandByCode(brandCode) {
      if (brandCode) {
        var uri = CMS_URI + 'api/view/extension-brand-code/' + brandCode;
        return call(uri);
      }
    }

    function getExtensionDraftBrandByCode(brandCode) {
      if (brandCode) {
        var uri = CMS_URI + 'api/view/extension-brand-code-draft/' + brandCode;
        return call(uri);
      }
    }

    function getBrandByCode(brandCode) {
      if (brandCode) {
        var uri = CMS_URI + 'api/view/brand-code/' + brandCode;
        return call(uri);
      }
    }



    function call(uri) {
      return $http.get(uri, { noAuth: true })
        .then(getData)
        .catch(error);

      function getData(response) {
        return response.data;
      }

      function error(error) {
        $log.error('XHR Failed for cms.' + error.data);
      }
    }

  }
})();
