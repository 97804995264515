(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .factory('BrandSearch', BrandSearch);

  BrandSearch.$inject = ['$resource', 'API_URI'];

  function BrandSearch($resource, API_URI) {
    var resourceUrl = API_URI + 'api/_search/brands/:id';

    return $resource(resourceUrl, {}, {
      'query': { method: 'GET', isArray: true }
    });
  }
})();
