(function () {
    'use strict';
    angular
        .module('garanteasyApp')
        .factory('WarrantyClaim', WarrantyClaim);

    WarrantyClaim.$inject = ['$resource', 'DateUtils', '$stateParams', 'API_URI'];

    function WarrantyClaim($resource, DateUtils, $stateParams, API_URI) {
        var resourceUrl = API_URI + 'api/warranty-claims/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.requestDate = DateUtils.convertLocalDateFromServer(data.requestDate);
                        data.closingDate = DateUtils.convertLocalDateFromServer(data.closingDate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var dataToSend = angular.copy(data);
                    dataToSend.requestDate = DateUtils.convertLocalDateToServer(dataToSend.requestDate);
                    dataToSend.closingDate = DateUtils.convertLocalDateToServer(dataToSend.closingDate);
                    return angular.toJson(dataToSend);
                }
            },
            'send': {
                method: 'PUT',
                url: API_URI + 'api/warranty-claims/:id/send',
                transformRequest: function (data) {
                    return angular.toJson(data);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var dataToSend = angular.copy(data);
                    dataToSend.requestDate = DateUtils.convertLocalDateToServer(dataToSend.requestDate);
                    dataToSend.closingDate = DateUtils.convertLocalDateToServer(dataToSend.closingDate);
                    return angular.toJson(dataToSend);
                }
            }
        });
    }
})();
