angular
  .module('garanteasyApp')
  .component('cardProduct', {
    templateUrl: 'app/components/cards/product/product.component.html',
    controller: CardProductComponentController,
    controllerAs: 'vm',
    bindings: {
      product: '<',
      isGift: '<',
      isExtension: '<',
      extension: '<'
    }
  });

CardProductComponentController.$inject = ['DataUtils', '$log', 'Product', '$http', 'CmsService', 'CMS_URI', 'ExtensionService', '$translate', '$state', 'AlertService', 'API_URI'];

function CardProductComponentController(DataUtils, $log, Product, $http, CmsService, CMS_URI, ExtensionService, $translate, $state, AlertService, API_URI) {
  var vm = this;
  vm.abbreviate = DataUtils.abbreviate;
  vm.image;
  vm.imageId;
  vm.selected = function (val) {
    ExtensionService.linkWarrantyToPurchasedProduct(vm.extension.sourcePurchasedProductId, val).then(onPurchasedProductWarrantySaveSuccess, onPurchasedProductWarrantySaveError);
  }

  vm.getWarrantiesStatus = function (arr) {

    var valid = arr
      .filter(function (x) { return x.status === 'valid'; });
    if (valid.length) {
      return 'valid';
    }
    valid = arr
      .filter(function (x) { return x.status === 'aboutToExpire'; });
    if (valid.length) {
      return 'aboutToExpire';
    }
    valid = arr
      .filter(function (x) { return x.status === 'expired'; });
    if (valid.length) {
      return 'expired';
    }
    return null;
  };

  function onDbImageLoadSuccess(response) {
    if (response.data) {
      vm.image = API_URI + 'api/product-resources/image/' + vm.imageId + '/xs';
    }
  }
  function onPurchasedProductWarrantySaveSuccess(result) {
    $translate("garanteasyApp.warranty.extension.associationCompleted").then(function (t) {
      AlertService.success(t);
    });
    $state.go('purchased-product-warranty', null, { reload: true });
  }
  function onPurchasedProductWarrantySaveError(result) {

  }


  function onImageLoadSuccess(response) {
    if (response.data) {
      vm.image = API_URI + 'api/product-resources/mainImage/' + vm.product.product.id + '?width=-&heigth=150';
    }
    else {
      loadProductImageFromDb();
    }
  }


  function loadProductImageFromProducerLogo() {
    if (vm.product.product.brand && vm.product.product.brand.code) {
      CmsService.getBrandByCode(vm.product.product.brand.code).then(function (responseBrand) {
        if (responseBrand[0]) {
          var logourl = responseBrand[0].brand_image_full || responseBrand[0].logo_image_full;
          if (logourl) {
            vm.image = CMS_URI + logourl;
          } else {
            loadProductImageFromDb();
          }
        } else {
          loadProductImageFromDb();
        }
      }, loadProductImageFromDb);
    }


  }

  function loadProductImageFromDb() {
    Product.get({ id: vm.product.product.id }, function (p) {

      if (p.images.length > 0 && p.images[0].id) {
        vm.imageId = p.images[0].id;
        $http({
          method: 'GET',
          url: API_URI + 'api/product-resources/image/' + vm.imageId + '/xs'
        }).then(onDbImageLoadSuccess);
      }
    });
  }

  vm.getShopType = function () {
    if (vm.product && vm.product.receipt && vm.product.receipt.shop && vm.product.receipt.shop.cmsShopData && vm.product.receipt.shop.cmsShopData.shop_type) {
      return vm.product.receipt.shop.cmsShopData.shop_type;
    }
  }
  vm.$onInit = function () {
    if (vm.product.product.id) {
      $http({
        method: 'GET',
        url: API_URI + 'api/product-resources/mainImage/' + vm.product.product.id + '?width=-&heigth=150'
      }).then(onImageLoadSuccess, loadProductImageFromProducerLogo);
    }

  };
}
