(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .controller('ProductDetailController', ProductDetailController);

  ProductDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'Principal', 'entity', 'Product', 'User', 'Brand', 'DataUtils', 'ProductResources', 'Maintenance', 'DEFAULT_BARCODE_ENCODING', 'API_URI'];

  function ProductDetailController($scope, $rootScope, $stateParams, previousState, Principal, entity, Product, User, Brand, DataUtils, ProductResources, Maintenance, DEFAULT_BARCODE_ENCODING, API_URI) {
    var vm = this;

    function getImageUrl(imageId) {
      return API_URI + "api/product-resources/image/" + imageId
    }

    function getImagesUrl() {
      for (var key in vm.product.images) {
        vm.product.images[key].url = getImageUrl(vm.product.images[key])
      }
    }

    function getManualUrl() {
      for (var key in vm.product.manuals) {
        vm.product.manuals[key].url = API_URI + "api/product-resources/manual/" + vm.product.manuals[key].id
      }
    }

    vm.barCodeOpt = undefined;
    vm.getBCOptions = function (format) {

      if (!vm.barCodeOpt) {

        vm.barCodeOpt = {
          format: (format || DEFAULT_BARCODE_ENCODING).toUpperCase(),
          lineColor: '#000000',
          width: 2,
          height: 100,
          displayValue: true,
          fontOptions: '',
          font: 'monospace',
          textAlign: 'center',
          textPosition: 'bottom',
          textMargin: 2,
          fontSize: 20,
          background: '#ffffff',
          margin: 0,
          marginTop: undefined,
          marginBottom: undefined,
          marginLeft: undefined,
          marginRight: undefined,
          valid: function (valid) {
          }
        };
      }

      return vm.barCodeOpt
    };
    vm.product = entity;
    getManualUrl();
    getImagesUrl();

    if (vm.product.images.length > 0) {
      vm.image = vm.product.images[0];
    }

    vm.changeMainImage = function (image) {
      if (vm.product.images != undefined && vm.product.images.length > 1) {
        $('.zoomContainer').remove();
        vm.image = image;
      }
    }

    if ($stateParams.from) {
      $scope.from = $stateParams.from
    }
    if ($stateParams.from2nd) {
      $scope.from2nd = $stateParams.from2nd
    }

    vm.isOwner = null;
    vm.isAdmin = null;




    checkAccess();

    function checkAccess() {
      Principal.identity().then(function (account) {
        vm.isOwner = vm.product.createdBy.id == account.id;
        Principal.hasAuthority('ROLE_ADMIN').then(function (result) {
          if (result) {
            vm.isAdmin = true;
          } else {
            vm.isAdmin = false;
          }
        });
      });
    }

    vm.uploadFile = function ($file) {
      vm.productResource = {};

      vm.byteSize = DataUtils.byteSize;
      vm.openFile = DataUtils.openFile;
      vm.fileTooLarge = false;
      if ($file && $file.$error === 'pattern') {
        return;
      }
      if ($file) {
        if ($file.size > 5120000) {
          vm.fileTooLarge = true;
          // Reset data in case there was a previous image selected
          vm.productResource.description = null;
          vm.productResource.productId = null;
          vm.productResource.fileData = null;
          vm.productResource.contentType = null;
          vm.productResource.type = null;
        } else {
          vm.fileTooLarge = false;
          DataUtils.toBase64($file, function (base64Data) {
            $scope.$apply(function () {
              vm.productResource.description = $file.name;
              vm.productResource.productId = vm.product.id;
              vm.productResource.fileData = base64Data;
              vm.productResource.contentType = $file.type;
              vm.productResource.type = ProductResources.RType.IMAGE;
              ProductResources.save(vm.productResource, function (result) {
                vm.product.images.push(result);
                if (result) {
                  vm.image = result;
                  $('.zoomContainer').remove();
                }
              });
            });
          });
        }
      }
    };

    vm.deleteImage = function (image) {
      ProductResources.delete({ id: image.id }, function () {
        var ind = vm.product.images.indexOf(image);
        vm.product.images.splice(ind, ind + 1);
        $('.zoomContainer').remove();
        vm.image = vm.product.images[0];
      });
    };

    var unsubscribe = $rootScope.$on('garanteasyApp:productUpdate', function (event, result) {
      vm.product = result;
      getManualUrl();
      getImagesUrl();
    });
    $scope.$on('$destroy', unsubscribe);
    $scope.$on('$destroy', function (event, next, current) {
      $('.zoomContainer').remove();
    });
  }
})();
