(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .factory('CompanyLookup', CompanyLookup);

    CompanyLookup.$inject = ['$resource', 'API_URI'];

    function CompanyLookup($resource, API_URI) {
        var resourceUrl = API_URI + 'api/_lookup/companies';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true }
        });
    }
})();
