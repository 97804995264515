(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .factory('PasswordResetFinish', PasswordResetFinish);

    PasswordResetFinish.$inject = ['$resource', 'API_URI'];

    function PasswordResetFinish($resource, API_URI) {
        var service = $resource(API_URI + 'api/account/reset_password/finish', {}, {});

        return service;
    }
})();
