(function () {
  'use strict';
  angular
    .module('garanteasyApp')
    .factory('DashboardUser', DashboardUser);

  DashboardUser.$inject = ['$resource', 'DateUtils', 'API_URI'];

  function DashboardUser($resource, DateUtils, API_URI) {
    var resourceUrl = API_URI + 'api/user-dashboard/:id';

    return $resource(resourceUrl, {}, {
      'getReceipts': {
        method: 'GET',
        url: API_URI + 'api/user-dashboard/receipts'
      },
      'getShops': {
        method: 'GET',
        url: API_URI + 'api/user-dashboard/shops'
      },
      'getPurchaseMode': {
        method: 'GET',
        url: API_URI + 'api/user-dashboard/purchaseMode'
      },
      'getPurchasedProducts': {
        method: 'GET',
        url: API_URI + 'api/user-dashboard/purchasedProducts'
      },
      'getWarranties': {
        method: 'GET',
        url: API_URI + 'api/user-dashboard/warranties'
      },
      'getAccessInfo': {
        method: 'GET',
        url: API_URI + 'api/user-dashboard/accessInfo'
      }
    });
  }
})();
