(function () {
    'use strict';
    angular
        .module('garanteasyApp')
        .factory('Card', Card);

    Card.$inject = ['$resource', 'DateUtils', 'API_URI'];

    function Card($resource, DateUtils, API_URI) {
        var resourceUrl = API_URI + 'api/cards/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true },
            'queryActive': {
                url: API_URI + 'api/cards/active/:receipt',
                method: 'POST',
                isArray: true,
                /*params: {receipt: "-1"},*/
                transformRequest: function (data) {
                    var d = DateUtils.convertDateTimeFromServer(data.buyingDate);
                    var dataToSend = { id: data.id, customer: { id: data.customer.id }, buyingDate: d };// //angular.copy(data);
                    return angular.toJson(dataToSend);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'customers': {
                url: API_URI + 'api/cards/customers/:userId',
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'get': {
                method: 'GET',
                isArray: false,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.activationDate = DateUtils.convertLocalDateFromServer(data.activationDate);
                        data.expirationDate = DateUtils.convertLocalDateFromServer(data.expirationDate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var dataToSend = angular.copy(data);
                    if (dataToSend.activationDate) {
                        dataToSend.activationDate = DateUtils.convertLocalDateToServer(dataToSend.activationDate);
                    }
                    if (dataToSend.expirationDate) {
                        dataToSend.expirationDate = DateUtils.convertLocalDateToServer(dataToSend.expirationDate);
                    }
                    return angular.toJson(dataToSend);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {

                    var dataToSend = angular.copy(data);
                    if (dataToSend.activationDate) {
                        dataToSend.activationDate = DateUtils.convertLocalDateToServer(dataToSend.activationDate);
                    }
                    if (dataToSend.expirationDate) {
                        dataToSend.expirationDate = DateUtils.convertLocalDateToServer(dataToSend.expirationDate);
                    }
                    return angular.toJson(dataToSend);

                },
                transformResponse: function (data, headers) {
                    var response = {};
                    response = angular.fromJson(data);
                    return response;
                }
            },
            'getMerchantPrivacyPolicyEnabled': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getCards4Customer': {
                url: API_URI + 'api/cards/active/:customerId',
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();
