(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('warranty-claim', {
                parent: 'entity',
                url: '/warranty-claim',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'garanteasyApp.warrantyClaim.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/warranty-claim/warranty-claims.html',
                        controller: 'WarrantyClaimController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '0',
                        squash: true
                    },
                    sort: {
                        value: 'receipt.buyingDate,desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('warrantyClaim');
                        $translatePartialLoader.addPart('feedback');
                        $translatePartialLoader.addPart('warrantyClaimStatus');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('receipt');
                        return $translate.refresh();
                    }]
                }
            })
            .state('warranty-claim.detail', {
                parent: 'entity',
                url: '/warranty-claim/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'garanteasyApp.warrantyClaim.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/warranty-claim/warranty-claim-detail.html',
                        controller: 'WarrantyClaimDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('warrantyClaim');
                        $translatePartialLoader.addPart('warrantyClaimStatus');
                        $translatePartialLoader.addPart('warrantyClaimFile');
                        $translatePartialLoader.addPart('feedback');
                        $translatePartialLoader.addPart('receipt');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'WarrantyClaim', function ($stateParams, WarrantyClaim) {
                        return WarrantyClaim.get({ id: $stateParams.id }).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'warranty-claim',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('warranty-claim.detail.edit', {
                parent: 'warranty-claim.detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/warranty-claim/warranty-claim-dialog.html',
                        controller: 'WarrantyClaimDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['WarrantyClaim', function (WarrantyClaim) {
                                return WarrantyClaim.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, { reload: false });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('warranty-claim.new', {
                parent: 'warranty-claim',
                url: '/{idPurchasedProduct}/new',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'garanteasyApp.warrantyClaim.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/warranty-claim/warranty-claim-new.html',
                        controller: 'WarrantyClaimNewController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('warrantyClaim');
                        $translatePartialLoader.addPart('feedback');
                        $translatePartialLoader.addPart('warrantyClaimStatus');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('receipt');
                        return $translate.refresh();
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'warranty-claim',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('warranty-claim.newWithReceipt', {
                parent: 'warranty-claim',
                url: '/receipt/{idReceipt}/new',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'garanteasyApp.warrantyClaim.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/warranty-claim/warranty-claim-new.html',
                        controller: 'WarrantyClaimNewController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('warrantyClaim');
                        $translatePartialLoader.addPart('feedback');
                        $translatePartialLoader.addPart('warrantyClaimStatus');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('receipt');
                        return $translate.refresh();
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'warranty-claim',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('warranty-claim.edit', {
                parent: 'warranty-claim',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/warranty-claim/warranty-claim-dialog.html',
                        controller: 'WarrantyClaimDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['WarrantyClaim', function (WarrantyClaim) {
                                return WarrantyClaim.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('warranty-claim', null, { reload: true });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('warranty-claim.delete', {
                parent: 'warranty-claim',
                url: '/{id}/delete',
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/warranty-claim/warranty-claim-delete-dialog.html',
                        controller: 'WarrantyClaimDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['WarrantyClaim', function (WarrantyClaim) {
                                return WarrantyClaim.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('warranty-claim', null, { reload: true });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('warranty-claim.send', {
                parent: 'warranty-claim.detail',
                url: '/{id}/send',
                ncyBreadcrumb: {
                    skip: true // Never display this state in breadcrumb.
                },
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/warranty-claim/warranty-claim-send-dialog.html',
                        controller: 'WarrantyClaimSendController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['WarrantyClaim', function (WarrantyClaim) {
                                return WarrantyClaim.get({ id: $stateParams.id }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('purchased-product', null, { reload: true });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('warranty-claim.feedback-new', {
                parent: 'warranty-claim.detail',
                url: '/{id}/addWarrantyClaimFeedback',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/feedback/feedback-dialog.html',
                        controller: 'FeedbackDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    description: null,
                                    creationDate: new Date(),
                                    feedbackType: 'WARRANTYCLAIM',
                                    id: null,
                                    parentId: $stateParams.id
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('warranty-claim.detail', null, { reload: true });
                    }, function () {
                        $state.go('warranty-claim.detail');
                    });
                }]
            })
            .state('warranty-claim.feedback-edit', {
                parent: 'warranty-claim.detail',
                url: '/{id}/editWarrantyClaimFeedback/{feedbackId}',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/feedback/feedback-dialog.html',
                        controller: 'FeedbackDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Feedback', function (Feedback) {
                                return Feedback.get({ id: $stateParams.feedbackId }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('warranty-claim.detail', null, { reload: true });
                    }, function () {
                        $state.go('warranty-claim.detail');
                    });
                }]
            })
            .state('warranty-claim.feedback-delete', {
                parent: 'warranty-claim.detail',
                url: '/{id}/deleteWarrantyClaimFeedback/{feedbackId}',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/feedback/feedback-delete-dialog.html',
                        controller: 'FeedbackDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Feedback', function (Feedback) {
                                return Feedback.get({ id: $stateParams.feedbackId }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('warranty-claim.detail', null, { reload: true });
                    }, function () {
                        $state.go('warranty-claim.detail');
                    });
                }]
            })
            .state('warranty-claim.warranty-claim-file-new', {
                parent: 'warranty-claim.detail',
                url: '/{id}/addWarrantyClaimFile',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/warranty-claim-file/warranty-claim-file-dialog.html',
                        controller: 'WarrantyClaimFileDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    fileName: null,
                                    fileContentType: null,
                                    warrantyClaimId: $stateParams.id,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('warranty-claim.detail', null, { reload: true });
                    }, function () {
                        $state.go('warranty-claim.detail');
                    });
                }]
            })
            .state('warranty-claim.warranty-claim-file-delete', {
                parent: 'warranty-claim.detail',
                url: '/{rfId}/deleteWarrantyClaimFile',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/warranty-claim-file/warranty-claim-file-delete-dialog.html',
                        controller: 'WarrantyClaimFileDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['WarrantyClaimFile', function (WarrantyClaimFile) {
                                return WarrantyClaimFile.get({ id: $stateParams.rfId }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('warranty-claim.detail', null, { reload: true });
                    }, function () {
                        $state.go('warranty-claim.detail');
                    });
                }]
            });
    }
})();
