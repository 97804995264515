(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .factory('Activate', Activate);

    Activate.$inject = ['$resource', 'API_URI'];

    function Activate($resource, API_URI) {
        var service = $resource(API_URI + 'api/activate', {}, {
            'get': { method: 'GET', params: {}, isArray: false }
        });

        return service;
    }
})();
