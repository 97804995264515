(function () {
  'use strict';

  angular
    .module('garanteasyApp')
    .factory('PurchasedProductSearch', PurchasedProductSearch);

  PurchasedProductSearch.$inject = ['$resource', 'DateUtils', '$log', 'API_URI'];

  function PurchasedProductSearch($resource, DateUtils, $log, API_URI) {
    var resourceUrl = API_URI + 'api/_search/purchased-products/:id';
    var transformResponse = function (data) {

      if (data) {
        data = angular.fromJson(data);
        data.deliveryDate = DateUtils.convertLocalDateFromServer(data.deliveryDate);
        data.giftNotificationDate = DateUtils.convertLocalDateFromServer(data.giftNotificationDate);

        data.warrantiesLaw = {};
        data.warrantiesLawStatus = 'white';
        data.warrantiesConventialStatus = 'white';
        data.assuranceAgreementStatus = 'white';
        data.assuranceContractStatus = 'white';
        data.otherWarrenties = [];
        if (data.purchasedProductWarranties) {
          data.purchasedProductWarranties.forEach(function (item) {

            item.expirationDate = DateUtils.convertLocalDateFromServer(item.expirationDate);

            var dateFrom = DateUtils.convertLocalDateFromServer(data.receipt.buyingDate);

            if (data.deliveryDate) {
              dateFrom = data.deliveryDate;
            }
            var dateTo = item.expirationDate;

            if (_.isNull(dateFrom)) {
              dateFrom = new Date();
            }
            dateFrom = new Date(dateFrom);

            const one_day = 1000 * 60 * 60 * 24;
            dateTo = new Date(dateTo);

            item.dayToExpiration = Math.round((dateTo.getTime() - new Date()) / one_day);
            // FIXME k3 da rivedere con flag sulla warranty
            item.dayToExpiration = Math.round((dateTo.getTime() - new Date()) / one_day);
            // FIXME k3 da rivedere con flag sulla warranty
            if (item.warranty.contractType === 'LEGAL') {
              data.warrantiesLaw = item;
              data.warrantiesLawStatus = item.color;
            } else if (item.warranty.contractType === 'CONVENTIONAL') {
              data.warrantiesConventialStatus = item.color;
            } else if (item.warranty.contractType === 'SERVICE_CONTRACT') {
              data.assuranceContractStatus = item.color;
            } else if (item.warranty.contractType === 'INSURANCE_POLICY') {
              data.assuranceContractStatus = item.color;
            } else {
              data.otherWarrenties.push(item);
            }
          });
        }
      }
      return data;

    };
    var transformResponseArray = function (data, headersGetter, status) {
      if (status === 200) {
        return _.map(angular.fromJson(data), transformResponse);
      } else {
        $log.error("Errore fetching data", data);
        return null;
      }
    };

    return $resource(resourceUrl, {}, {
      'query': { method: 'GET', isArray: true, transformResponse: transformResponseArray }
    });
  }
})();
