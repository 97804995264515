(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .controller('WarrantyClaimDialogController', WarrantyClaimDialogController);

    WarrantyClaimDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'WarrantyClaim', 'PurchasedProduct', 'Principal', 'Customer', 'DataUtils', 'WarrantyClaimFile', '$state', 'AuthServerProvider', 'API_URI'];

    function WarrantyClaimDialogController($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, WarrantyClaim, PurchasedProduct, Principal, Customer, DataUtils, WarrantyClaimFile, $state, AuthServerProvider, API_URI) {
        var vm = this;
        vm.ppId = $stateParams.ppId;
        vm.listProducts = $stateParams.listProducts;

        vm.loadPurchasedProduct = function () {
            if (vm.warrantyClaim.purchasedProductId) {
                PurchasedProduct.get({ id: vm.warrantyClaim.purchasedProductId }, onSuccessPP, onErrorPP);
            }
        };

        vm.warrantyClaim = entity;
        if (vm.ppId) {
            vm.warrantyClaim.purchasedProductId = vm.ppId;
            vm.loadPurchasedProduct();
        } else
            vm.warrantyClaim.purchasedProductId = undefined;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.isValidInput = isValidInput;



        $scope.loadAddresses = function (searchQuery) {
            if (searchQuery && searchQuery.trim().length > 0) {
                Addr.query({ query: searchQuery }, function (result) {
                    $scope.products = result;
                }, function (response) {
                    if (response.status === 404) {
                        $scope.products = Product.query({ isActive: true }, onSuccess, onError);
                    }
                });
            } else {
                $scope.addresses = vm.addresses;
            }
        };



        function onSuccessPP(result) {
            vm.warrantyClaim.purchasedProduct = result;
            vm.customer = result.receipt.customer;
            if (vm.customer) {
                vm.addresses = vm.customer.addresses;
            }
        }

        function onErrorPP() {

        }

        $scope.resetAddress = function () {
            vm.warrantyClaim.addressId = null;
            vm.warrantyClaim.address = null;
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.warrantyClaim.id !== null) {
                WarrantyClaim.update(vm.warrantyClaim, onSaveSuccess, onSaveError);
            } else {
                WarrantyClaim.save(vm.warrantyClaim, onSaveSuccess, onSaveError);
            }
        }

        // Constraints beyond the ones in the form
        function isValidInput() {
            // For closed claims closing date is mandatory
            return vm.warrantyClaim.status == 'WAITING' || vm.warrantyClaim.status == 'OPEN' || vm.warrantyClaim.closingDate != null;
        }

        function onSaveSuccess(result) {
            if (angular.isDefined(vm.warrantyClaimFile)) {
                vm.warrantyClaimFile.warrantyClaimId = result.id;
                WarrantyClaimFile.save(vm.warrantyClaimFile, function (result) {
                    vm.images.push(result);
                    if (result) {
                        vm.image = result;
                    }
                });
            }
            $uibModalInstance.close(result);
            vm.isSaving = false;
            $state.go('purchased-product');
            //WarrantyClaim.send(result, onSendSuccess, onSendError);
        }

        //function onSendSuccess(result) { }

        //function onSendError(result) { }

        function onSaveError() {
            vm.isSaving = false;
        }

        //vm.datePickerOpenStatus.requestDate = false;
        vm.datePickerOpenStatus.closingDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.canShow = function (contentType) {
            return contentType != null && contentType.toLowerCase().indexOf('image') == 0 && contentType.toLowerCase().indexOf('tiff') == -1;
        }

        vm.uploadFile = function ($file) {
            vm.warrantyClaimFile = {};
            vm.byteSize = DataUtils.byteSize;
            vm.openFile = DataUtils.openFile;
            vm.fileTooLarge = false;
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                if ($file.size > 5120000) {
                    vm.fileTooLarge = true;
                    // Reset data in case there was a previous image selected
                    vm.warrantyClaimFile.fileName = null;
                    vm.warrantyClaimFile.fileData = null;
                    vm.warrantyClaimFile.contentType = null;
                    vm.warrantyClaimFile.type = null;
                } else {
                    vm.fileTooLarge = false;
                    DataUtils.toBase64($file, function (base64Data) {
                        $scope.$apply(function () {
                            vm.warrantyClaimFile.fileData = base64Data;
                            vm.warrantyClaimFile.fileContentType = $file.type;
                            vm.warrantyClaimFile.fileName = $file.name;
                            vm.image = vm.warrantyClaimFile;
                            /*if(item.fileContentType && item.fileContentType.toLowerCase().indexOf("image") == 0){*/
                            vm.imageNotPersistent = vm.warrantyClaimFile;

                        });
                    });
                }
            }
        };

        var imagesPath = {};
        vm.getImageUrl = function (warrantyClaimFile) {
            if (!warrantyClaimFile || !warrantyClaimFile.id) {
                imagesPath[warrantyClaimFile.id] = null;
            } else if (_.isUndefined(imagesPath[warrantyClaimFile.id])) {
                imagesPath[warrantyClaimFile.id] = API_URI + "api/warranty-claim-files/" + warrantyClaimFile.id + "?width=-&height=377&token=" + AuthServerProvider.getToken();
            }
            return imagesPath[warrantyClaimFile.id];

        };
        // if (vm.warrantyClaim.warrantyClaimFiles !== null  {
        //     vm.receipt.receiptFiles.forEach(function(item) {
        //         if (item.fileContentType && item.fileContentType.toLowerCase().indexOf("image") === 0)
        //             vm.images.push(item);
        //         else
        //             vm.documents.push(item);
        //     })
        // }



    }
})();
