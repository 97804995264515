(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .factory('Account', Account);

    Account.$inject = ['$resource', 'API_URI'];

    function Account($resource, API_URI) {
        var service = $resource(API_URI + 'api/account', {}, {
            'get': {
                method: 'GET', params: {}, isArray: false,
                interceptor: {
                    response: function (response) {
                        // expose response
                        return response;
                    }
                }
            },
            'deleteUser': {
                method: 'POST',
                url: API_URI + 'api/account/delete_user/:login_confirm',
                transformRequest: function (data) {
                    var dataToSend = angular.copy(data);
                    return angular.toJson(dataToSend);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);

                    }
                    return data;
                }
            }
            // vm.cards = Card.queryActive(vm.receipt);
        });

        return service;
    }
})();
