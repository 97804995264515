(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .factory('ActivateEmail', ActivateEmail);

    ActivateEmail.$inject = ['$resource', 'API_URI'];

    function ActivateEmail($resource, API_URI) {
        var service = $resource(API_URI + 'api/activateEmail', {}, {
            'get': { method: 'GET', params: {}, isArray: false }
        });

        return service;
    }
})();
