(function () {
    'use strict';
    angular
        .module('garanteasyApp')
        .factory('Gift', Gift);

    Gift.$inject = ['$resource', 'DateUtils', 'API_URI'];

    function Gift($resource, DateUtils, API_URI) {
        var resourceUrl = API_URI + 'api/gifts/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.notificationDate = DateUtils.convertLocalDateFromServer(data.notificationDate);
                    }
                    return data;
                }
            },
            /* isArray=true because it returns the list of receipts, since most of the times
             * the receipt is no longer visible to the gift receiver after removing a gift. */
            'delete': { method: 'DELETE', isArray: true },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var dataToSend = angular.copy(data);
                    dataToSend.notificationDate = DateUtils.convertLocalDateToServer(dataToSend.notificationDate);
                    return angular.toJson(dataToSend);
                }
            }
        });
    }
})();
