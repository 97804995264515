'use strict';
(function () {

    angular
        .module('garanteasyApp')
        .factory('BrandService', BrandService);

    BrandService.$inject = ['$http', 'CMS_URI', '$log'];

    function BrandService($http, CMS_URI, $log) {
        var service = {
            getBrandData: getBrandData
        };

        return service;



        function getBrandData(id) {
            if (id) {
                var uri = CMS_URI + 'api/view/brand/' + id;
                return call(uri);
            }
        }



        function call(uri) {
            return $http.get(uri, { noAuth: true })
                .then(getData)
                .catch(error);

            function getData(response) {
                return response.data;
            }

            function error(error) {
                $log.error('XHR Failed for brands.' + error.data);
            }
        }

    }
})();
