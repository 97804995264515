(function () {
    'use strict';
    angular
        .module('garanteasyApp')
        .factory('Feedback', Feedback);

    Feedback.$inject = ['$resource', 'DateUtils', '$stateParams', 'API_URI'];

    function Feedback($resource, DateUtils, $stateParams, API_URI) {
        var resourceUrl = API_URI + 'api/feedbacks/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.creationDate = DateUtils.convertLocalDateFromServer(data.creationDate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    var dataToSend = angular.copy(data);
                    dataToSend.creationDate = DateUtils.convertLocalDateToServer(dataToSend.creationDate);
                    return angular.toJson(dataToSend);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    var dataToSend = angular.copy(data);
                    dataToSend.creationDate = DateUtils.convertLocalDateToServer(dataToSend.creationDate);
                    return angular.toJson(dataToSend);
                }
            }
        });
    }
})();
