angular
    .module('garanteasyApp')
    .component('cardReceipt', {
        templateUrl: 'app/components/cards/receipt/receipt.component.html',
        controller: CardReceiptComponentController,
        controllerAs: 'vm',
        bindings: {
            receipt: '<'
        }
    });

CardReceiptComponentController.$inject = ['AuthServerProvider', 'API_URI'];

function CardReceiptComponentController(AuthServerProvider, API_URI) {
    var vm = this;

    vm.evaluateClass = function () {
        var result = 'cardheader';
        if (vm.receipt) {
            if (vm.receipt.receiptType === 'RECEIPT') {
                return (result + ' receipt-background');
            } else {
                return (result + ' invoice-background');
            }
        }
    }

    vm.getImageUrl = function (receipt) {
        if (((receipt || {}).receiptFiles || {}).length > 0) {
            var image = _.find(receipt.receiptFiles, function (o) { return o.fileContentType.startsWith('image/'); });
            if (image) {
                return API_URI + "api/receipt-files/content/" + image.id + "?width=-&height=150&token=" + AuthServerProvider.getToken();
            } else {
                return null;
            }
        }
    };
}
