(function () {
  'use strict';

  var giftNewComponent = {
    templateUrl: 'app/entities/gift/components/gift-new-component.html',
    controller: GiftNewController,
    controllerAs: 'vm',
    bindings: {
      receipt: '<',
      giftInfo: '<',
      purchasedProduct: '<',
      isDetail: '<'
    }
  };

  angular
    .module('garanteasyApp')
    .component('giftNewComponent', giftNewComponent);

  GiftNewController.$inject = ['$scope', 'Warranty', '$log', 'API_URI'];



  function GiftNewController($scope, Warranty, $log, API_URI) {
    var vm = this;
    $scope.$watch('vm.purchasedProduct', function (purchasedProduct) {
      vm.purchasedProduct = purchasedProduct;
      if (purchasedProduct && purchasedProduct.product) {
        vm.purchasedProduct.mainImageUrl = API_URI + "api/product-resources/mainImage/" + purchasedProduct.product.id;
      }
    }, true);
  }
})();
